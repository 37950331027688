:root {
  --feature-color: #69eaff;
  --dark-feature-color: #50c7da;
  --light-grey: #e6e6e6;
  --medium-grey: #cacaca;
  --dark-grey: #2f2f2f;
}

html, body {
  color: var(--dark-grey);
}

a {
  color: var(--feature-color);
}

a:hover {
  color: var(--feature-color);
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

h1 {
  margin: 1rem 0 2rem;
}

h2 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.15rem;
}

.container {
  margin-bottom: 3em;
}

.form-control {
  height: 45px;
}

.btn {
  align-items: center;
  border: none;
  color: var(--dark-grey);
  display: flex;
  height: 45px;
  justify-content: center;
}

.btn-success, .btn-secondary {
  font-size: 18px;
  margin-top: 32px;
}

.btn svg {
  margin-right: .25rem;
}

.btn-success {
  margin-bottom: 48px;
}

.btn-success:hover,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--dark-feature-color);
  color: var(--dark-grey);
}

.btn-primary:focus {
  border-color: var(--feature-color);
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--dark-feature-color);
  box-shadow: none;
  color: var(--dark-grey);
}

.btn-success, .btn-primary {
  background-color: var(--feature-color);
}

.btn-secondary, .btn-dark {
  color: white;
}

.btn-light {
  background-color: var(--light-grey);
}

.btn-light:hover {
  background-color: var(--medium-grey);
}

.active {
  background-color: var(--dark-grey);
  border-radius: 4px;
  color: white;
  padding: 1rem;
}

.rating-container {
  background-color: var(--dark-grey);
  border-radius: 4px;
  color: var(--light-grey);
  padding: 1rem;
  margin: 1rem 0;
}

.reset {
  height: 50px;
  border-radius: 25px;
  margin: 0 auto;
  background-color: #5d5d5d;
  color: white;
}

.rating {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  margin: 0 auto;
}

.rating-buttons {
  margin: 1.25rem 0 2rem;
}

.highlight-summary {
  align-items: center;
  background-color: var(--light-grey);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 1rem;
}

.highlight-summary p {
  margin-bottom: 0;
  margin-right: 1rem;
}

.highlight-summary button {
  max-width: 240px;
}

.skill-lane {
  border-top: 1px solid var(--light-grey);
  margin-top: 1rem;
  padding-top: 1rem;
}

.skill {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.skill p {
  margin: auto 0;
}

.skill button {
  margin-top: 1rem;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder svg {
  margin-right: .25rem;
}
